import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'
import { TUser } from 'src/graphql'
import { getAccountPermissionTxt } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { UserUtils } from 'src/utils'

interface Actions {
  user: TUser | null
}

export const renderColumns = ({ user }: Actions) => [
  {
    title: 'User name',
    dataIndex: 'username',
    key: 'username',
    render: (_: any, record: TUser) => UserUtils.getFullName(record)
  },
  {
    title: 'User ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'User Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Permissions',
    dataIndex: 'accountOffChainPermissionsExtended',
    key: 'permission',
    render: (value?: string[]) => (
      <Space direction="vertical">
        {value?.map(el => (
          <span key={el}>{getAccountPermissionTxt(el)}</span>
        ))}
      </Space>
    )
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: any, record: TUser) => (
      <Space>
        {!!user && (
          <Button
            size="small"
            type="link"
          >
            <Link to={generate([ERoutes.CSR_BUSINESS_USER_DETAIL, { businessId: user.id, userId: record.id }])}>
              Detail
            </Link>
          </Button>
        )}
      </Space>
    )
  }
]
