import { graphql } from 'relay-runtime'

import { InitUserIdentityMutation } from './__generated__/InitUserIdentityMutation.graphql'

export type { InitUserIdentityMutation }

export const initUserIdentityMutation = graphql`
  mutation InitUserIdentityMutation($input: InitUserIdentityInput!) {
    initUserIdentity(input: $input) {
      data {
        ...on UserIdentity {
          id
        }
      }
    }
  }
`
