import { Input, Table } from 'antd'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { LogsFormat, Pagination } from 'src/atoms'
import { KolActionLogsQuery, kolActionLogsQuery, TKolActionLog } from 'src/graphql'
import { useBehaviorMapper, useDidMountDebounce } from 'src/hooks'
import { PaginationService, TzService } from 'src/services'
import { JsonUtils, UserUtils } from 'src/utils'

interface IProps {
  header: ReactNode
  isBusiness?: boolean
}

export const BdActionLogs: FC<IProps> = (props) => {
  const _paginationService = useMemo(() => new PaginationService<TKolActionLog>(kolActionLogsQuery), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.items$)

  const [filter, setFilter] = useState<{ email: string }>({ email: '' })

  const computedWhere = useMemo<KolActionLogsQuery['variables']['where']>(() => {
    const emailTrimmed = filter.email.trim()
    const conditions: KolActionLogsQuery['variables']['where'] = {
      hasKolWith: [{ isBusiness: props?.isBusiness ?? false }]
    }
    if (emailTrimmed) {
      conditions.hasBdWith = [{ emailContainsFold: emailTrimmed }]
    }
    return conditions
  }, [filter, props?.isBusiness])

  const fetch = useCallback(
    (params?: Parameters<typeof _paginationService.fetch>[0]) => _paginationService.fetch(params),
    [_paginationService]
  )

  useDidMountDebounce(() => {
    fetch({
      after: '',
      before: '',
      where: computedWhere
    })
  }, 500, [computedWhere, fetch])

  useEffect(() => { fetch() }, [fetch])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        {props.header}

        <div className="fx fx-ai-center gap-2 fx-jc-end">
          <Input.Search
            allowClear
            placeholder="Search by email"
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={filter.email}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              email: e.target.value
            }))}
          />
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        dataSource={dataSource}
        pagination={false}
        columns={[
          {
            title: 'Edited time',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (val: TKolActionLog['createTime'], record: TKolActionLog) => val
              ? TzService.format(val, 'HH:mm YYYY-MM-DD')
              : '--'
          },
          {
            title: 'Edited by',
            dataIndex: 'by',
            key: 'by',
            render: (_: any, record: TKolActionLog) => UserUtils.getFullName(record?.bd)
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_: any, record: TKolActionLog) => record?.bd?.email
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action'
          },
          {
            title: 'Previous status & details',
            dataIndex: 'pre_detail',
            key: 'pre_detail',
            render: (_: any, record: TKolActionLog) => (
              <LogsFormat
                state="old"
                data={JsonUtils.parse(record.data)}
                format={(val, field) => ['CommissionRate', 'DirectCommissionRate', 'IndirectCommissionRate'].includes(field as string)
                  ? val
                    ? Number(val) / 100 + '%'
                    : ''
                  : val}
              />
            )
          },
          {
            title: 'New status & details',
            dataIndex: 'new_detail',
            key: 'new_detail',
            render: (_: any, record: TKolActionLog) => (
              <LogsFormat
                state="new"
                data={JsonUtils.parse(record.data)}
                format={(val, field) => ['CommissionRate', 'DirectCommissionRate', 'IndirectCommissionRate'].includes(field as string)
                  ? val
                    ? Number(val) / 100 + '%'
                    : ''
                  : val}
              />
            )
          },
          {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: 140
          }
        ]}
        tableLayout="fixed"
        scroll={{ x: true }}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        canPrev={_paginationService.canPrev}
        canNext={_paginationService.canNext}
        onPrev={() => _paginationService.prev()}
        onNext={() => _paginationService.next()}
      />
    </section>
  )
}
