import { Input, Table, message } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Pagination } from 'src/atoms'
import { AdminPortalUsersQuery, TAdminPortalUser, adminPortalUsersQuery } from 'src/graphql'
import { useBehaviorMapper, useDidMountDebounce } from 'src/hooks'
import { EAdminPortalPermission } from 'src/interfaces'
import { DialogAddAdmin } from 'src/partials'
import { EPaths } from 'src/router'
import { AuthService, BreadcrumbService, PaginationService } from 'src/services'
import { renderColumns } from './columns'

const View: FC = () => {
  const _paginationService = useMemo(() => new PaginationService<TAdminPortalUser>(adminPortalUsersQuery), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.items$)

  const [filter, setFilter] = useState({ keyword: '' })

  const computedWhere = useMemo<AdminPortalUsersQuery['variables']['where']>(() => {
    const keywordTrimmed = filter.keyword.trim()
    return ({
      or: [
        ...(keywordTrimmed
          ? [
            { emailContainsFold: keywordTrimmed },
            { firstNameContainsFold: keywordTrimmed },
            { lastNameContainsFold: keywordTrimmed }
          ]
          : [])
      ]
    })
  }, [filter])

  const fresh = useCallback(
    (params?: Parameters<typeof _paginationService.fetch>[0]) => _paginationService.fetch(params),
    [_paginationService]
  )

  useDidMountDebounce(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  }, 500, [computedWhere, fresh])

  useEffect(() => { fresh() }, [fresh])

  const onRemove = useCallback(() => message.info('Feature under development'), [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search by name & email"
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />
        </div>

        <DialogAddAdmin afterClose={(doc) => doc && fresh()}/>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        columns={renderColumns({ onRemove, onFresh: fresh })}
        scroll={{ x: true }}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        canPrev={_paginationService.canPrev}
        canNext={_paginationService.canNext}
        onPrev={() => _paginationService.prev({ where: computedWhere })}
        onNext={() => _paginationService.next({ where: computedWhere })}
      />

      {/* <ModalCreateAdmin
        open={openModal}
        confirmLoading={loading}
        onOk={handleNewAdmin}
        onCancel={onCloseModal}
      /> */}
    </section>
  )
}

export const AdminManagement: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.ADMIN_MANAGEMENT,
      label: 'Admin Portal'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const user = useBehaviorMapper(AuthService.user$)
  const [haveAdminPermission, setHaveAdminPermission] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (!user?.id) {
      return
    }

    setHaveAdminPermission(
      user.adminPortalUserPermissionsExtended?.includes(EAdminPortalPermission.ADMIN_PORTAL_ADMIN) ||
      user.adminPortalUserPermissionsExtended?.includes(EAdminPortalPermission.ADMIN_PORTAL_COMPLIANCE_SUPER)
    )
  }, [user])

  if (haveAdminPermission === undefined) {
    return null
  }

  return haveAdminPermission ? <View/> : <NotAdminUI/>
}

const NotAdminUI: FC = () => {
  const user = useBehaviorMapper(AuthService.user$)

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        dataSource={[user as TAdminPortalUser]}
        columns={renderColumns({ noActions: true })}
        scroll={{ x: true }}
      />
    </section>
  )
}
