import { CopyOutlined } from '@ant-design/icons'
import { Descriptions, DescriptionsProps } from 'antd'
import { FC, useMemo } from 'react'
import { TUser } from 'src/graphql'
import { ClipboardService } from 'src/services'

interface IProps {
  user: TUser | null
}

export const UserProfile: FC<IProps> = ({ user }) => {
  const items: DescriptionsProps['items'] = useMemo(() => ([
    {
      key: '1',
      label: 'Display name',
      children: user?.account.displayName
    },
    {
      key: '2',
      label: 'User ID',
      children: user?.id
        ? (
          <div className="fx fx-ai-center gap-1">
            {user.id}
            <CopyOutlined
              className="pointer"
              onClick={() => ClipboardService.copy(user.id)}
            />
          </div>
        )
        : '--'
    },
    {
      key: '3',
      label: 'Account ID',
      children: user?.account.id
        ? (
          <div className="fx fx-ai-center gap-1">
            {user.account.id}
            <CopyOutlined
              className="pointer"
              onClick={() => ClipboardService.copy(user.account.id)}
            />
          </div>
        )
        : '--'
    },
    {
      key: '4',
      label: 'First name',
      children: user?.firstName
    },
    {
      key: '5',
      label: 'Last name',
      children: user?.lastName
    },
    {
      key: '6',
      label: 'Email',
      children: user?.email
    },
    {
      key: '7',
      label: 'Funding account address',
      children: user?.account?.chainAccountAddress
        ? (
          <div className="fx fx-ai-center gap-1">
            {user.account.chainAccountAddress}
            <CopyOutlined
              className="pointer"
              onClick={() => ClipboardService.copy(user.account.chainAccountAddress)}
            />
          </div>
        )
        : '--'
    },
    {
      key: '8',
      label: 'Sub account IDs',
      children: user?.account?.subAccounts?.map(({ id }) => (
        <div className="fx fx-ai-center gap-1" key={id}>
          {id}
          <CopyOutlined
            className="pointer"
            onClick={() => ClipboardService.copy(id)}
          />
        </div>
      ))
    }
  ].map(el => ({
    ...el,
    labelStyle: {
      width: '50%'
    }
  }))), [user])

  return (
    <section className="fx fx-column fx-extend">
      <Descriptions layout="horizontal" size="small" column={1} bordered items={items}/>
    </section>
  )
}
