import { Button, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { EReferralLevelTxt, TKolStatistics } from 'src/graphql'
import { DialogCommissionRate, DialogEditBDInCharge, DialogReferralLevel } from 'src/partials'
import { EPaths, generate } from 'src/router'
import { TzService } from 'src/services'
import { NumberUtils } from 'src/utils'

const { Paragraph } = Typography

interface Actions {
  onFresh?: () => void
}

export const renderColumns = ({ onFresh }: Actions) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: 'break-all min-w-150'
  },
  {
    title: 'Account ID',
    dataIndex: 'accountID',
    key: 'accountID',
    className: 'break-all min-w-200',
    render: (accountID: TKolStatistics['accountID']) => (
      <Paragraph copyable>{accountID}</Paragraph>
    )
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    className: 'break-all min-w-200'
  },
  {
    title: 'Date of join',
    dataIndex: 'joinedDate',
    key: 'joinedDate',
    render: (val: TKolStatistics['joinedDate']) => TzService.format(Number(val) * 1000, 'YYYY-MM-DD'),
    className: 'wp-no-wrap'
  },
  {
    title: 'Direct invites',
    dataIndex: 'totalDirectReferrals',
    key: 'totalDirectReferrals',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Indirect invites',
    dataIndex: 'totalIndirectReferrals',
    key: 'totalIndirectReferrals',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Total points',
    dataIndex: 'totalPoints',
    key: 'totalPoints',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: any, record: any) => (
      <Space>
        <Button
          size="small"
          type="link"
        >
          <Link
            to={generate([EPaths.BD_KOL_REWARD_DETAILS, {
              accountID: record.accountID
            }])}
          >
            Reward Details
          </Link>
        </Button>
      </Space>
    )
  },
  {
    title: 'Trading Vol. (USD)',
    dataIndex: 'tradingVolume',
    key: 'tradingVolume',
    render: (val: TKolStatistics['tradingVolume'], record: TKolStatistics) => NumberUtils.formatNumber(Number(val?.slice(0, -16)) / 100, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Direct Invite Trading Vol.(USD)',
    dataIndex: 'directInviteTradingVolume',
    key: 'directInviteTradingVolume',
    render: (val: TKolStatistics['directInviteTradingVolume'], record: TKolStatistics) => NumberUtils.formatNumber(Number(val?.slice(0, -16)) / 100, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Indirect Invite Trading Vol.(USD)',
    dataIndex: 'indirectInviteTradingVolume',
    key: 'indirectInviteTradingVolume',
    render: (val: TKolStatistics['indirectInviteTradingVolume'], record: TKolStatistics) => NumberUtils.formatNumber(Number(val?.slice(0, -16)) / 100, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Direct Invite Trading Fee (USD)',
    dataIndex: 'directInviteTradingFee',
    key: 'directInviteTradingFee',
    render: (val: TKolStatistics['directInviteTradingFee'], record: TKolStatistics) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Indirect Invite Trading Fee (USD)',
    dataIndex: 'indirectInviteTradingFee',
    key: 'indirectInviteTradingFee',
    render: (val: TKolStatistics['indirectInviteTradingFee'], record: TKolStatistics) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Direct Commission Rate',
    dataIndex: 'directCommissionRate',
    key: 'directCommissionRate',
    width: 150,
    className: 'break-word min-w-150',
    render: (val: TKolStatistics['directCommissionRate'], record: TKolStatistics) => (
      <div className="fx fx-ai-center gap-2">
        <DialogCommissionRate
          accountID={record.accountID}
          fieldChange="directCommissionRate"
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {val ? Number(val) / 100 + '%' : ''}
        </DialogCommissionRate>
      </div>
    )
  },
  {
    title: 'Indirect Commission Rate',
    dataIndex: 'indirectCommissionRate',
    key: 'indirectCommissionRate',
    width: 150,
    className: 'break-word min-w-150',
    render: (val: TKolStatistics['indirectCommissionRate'], record: TKolStatistics) => (
      <div className="fx fx-ai-center gap-2">
        <DialogCommissionRate
          accountID={record.accountID}
          fieldChange="indirectCommissionRate"
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {val ? Number(val) / 100 + '%' : ''}
        </DialogCommissionRate>
      </div>
    )
  },
  {
    title: 'Tier',
    dataIndex: 'tier',
    key: 'tier',
    render: (_: any, record: TKolStatistics) => (
      <DialogReferralLevel
        accountID={record.accountID}
        value={record.tier}
        btnProps={{ size: 'small', type: 'link' }}
        afterClose={(fresh) => fresh && onFresh?.()}
      >
        {EReferralLevelTxt[record.tier!]}
      </DialogReferralLevel>
    ),
    className: 'wp-no-wrap'
  },
  {
    title: 'Epoch Earnings (USD)',
    dataIndex: 'epochEarning',
    key: 'epochEarning',
    render: (val: TKolStatistics['epochEarning'], record: TKolStatistics) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'BD in charge',
    dataIndex: 'bdInCharge',
    key: 'bdInCharge',
    width: 150,
    className: 'break-word min-w-150',
    render: (val: TKolStatistics['bdInCharge'], record: TKolStatistics) => (
      <div className="fx fx-ai-center gap-2">
        <DialogEditBDInCharge
          accountID={record.accountID}
          bdInCharge={record.bdInCharge?.map(el => el.id)}
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {String(val?.length)}
        </DialogEditBDInCharge>
      </div>
    )
  }
]
