import { FC, useCallback } from 'react'

interface IProps {
  state?: 'new' | 'old'
  data: any
  format?: (value?: any, field?: string) => any
}

export const LogsFormat: FC<IProps> = ({ data, state, format }) => {
  const printDefaultObject = useCallback(() => state ? <LogsFormat data={data?.[state] || data}/> : <LogsFormat data={data}/>, [data, state])

  if (Array.isArray(data)) {
    return (
      <div className="fx-column">
        {data.map((el, index) => (
          <div key={el.id || index}>
            <LogsFormat
              state={state}
              data={el}
              format={format}
            />
          </div>
        ))}
      </div>
    )
  }

  switch (typeof data) {
    case 'string':
      return format?.(data) ?? data.split('_').join(' ')
    case 'object':
      return (
        <>
          {data?.field ? `${data.field}: ` : null}
          {format?.(state ? data?.[state] || data : data, data?.field) ?? printDefaultObject()}
        </>
      )
    default:
      return format?.(data) ?? data
  }
}
